<template>
  <div class="order-management">
    <pageBread
      :img="() => require('../../assets/image/zhuye.png')"
      text="包裹认领"
    ></pageBread>
    <div>
      <div class="title">
        <!-- <el-select
              v-model="formData.dept"
              placeholder="请选择科室"
              size="small"
              style="width: 320px"
              clearable
              @change="forceUpdate"
            >
              <el-option
                v-for="item in filterElements[3].options"
                :key="item.value"
                :label="item.label"
                :value="item.value + '/' + item.label"
              /> -->
        <select>
          <option value="" selected="selected">全部</option>
          <option v-for="exo in selectList" :key="exo.value" :value="exo.value">{{ exo.label }}</option>
        </select>
        <div>
          <!-- <span>快递单号：</span> -->
          <input v-model.trim="oddNumber" type="text" placeholder="请输入快递单号（必填）" onkeyup="this.value=this.value.replace(/\s+/g,'')">
        </div>
        <div style="text-align:right">
          <button class="nq-button" @click="search">查询</button>
        </div>
      </div>
    </div>
    <ScrollView>
      <div class="nqFrame" v-for="item in shipList" :key="item" v-wait="loading">
        <div class="flex">
          <div class="image" @click="claimDetails(item)">
            <h3>快递单号：{{ item.oddNumber }}</h3>
            <img src="../../assets/image/9.png" alt="">
          </div>
          <div class="baoguo">
            <span>时间：{{ item.createTime }}</span>
            <span style="margin-left:30px">仓库：{{ item.stockLocation }}</span>
          </div>
          <div style="text-align:right;margin-right:20px">
            <button class="nq-button copyLi-button" @click="claim">认领</button>
          </div>
        </div>
      </div>
    </ScrollView>
    <!-- 弹框一 -->
    <div v-if="msgTilte === '包裹认领'">
      <nqDialog
        :title="msgTilte"
        :visible="msgVisible"
        @close="msgVisible = false"
      >
        <div style="text-align:center;margin-top:30px">
          <h3>您确定认领该包裹吗？</h3>
        </div>
        <div style="padding: 8px">
          <div class="btn">
            <button style="margin: 20px; margin-left: 0 !important;" class="nq-button" @click="close = msgVisible = false">关闭</button>
            <button style="margin: 20px; margin-left: 0 !important;" class="nq-button copy-button" @click="saveData">确定</button>
          </div>
        </div>
      </nqDialog>
    </div>
    <!-- 弹框二 -->
    <div v-if="msgTilte === '包裹详情'">
      <nqDialog
        :title="msgTilte"
        :visible="msgVisible"
        @close="msgVisible = false"
      >
        <div style="margin-top:30px" class="nqFrame">
          <div style="margin-bottom: 10px">
            单号：<span>{{ frameList.oddNumber }}</span>
          </div>
          <div class="ware-house-item">
            <span>重量：{{ frameList.weight }}</span>
            <span>入库时间：{{ frameList.createTime }}</span>
          </div>
          <div class="ware-house-item">
            <span>库存天数：{{ frameList.stockDays }}</span>
            <span>备注：{{ frameList.note }}</span>
          </div>
        </div>
        <!-- <div style="padding: 8px">
          <div class="btn">
            <button class="nq-button copy-button" @click="saveData">确定</button>
            <button class="nq-button" @click="close = msgVisible = false">关闭</button>
          </div>
        </div> -->
      </nqDialog>
    </div>
  </div>
</template>
<script>
import { parcelInfo } from '../../utils/coupon.js'
export default {
  name: 'parcel',
  data() {
    return {
      msgTilte: '',
      msgVisible: false,
      showDetail: false,
      oddNumber: '',
      shipList: [
        // {
        //   oddNumber: '20120308',
        //   createTime: '2022-3-14',
        //   stockLocation: '第一个仓库'
        // },
        // {
        //   oddNumber: '20120308',
        //   createTime: '2022-3-14',
        //   stockLocation: '第二个仓库'
        // }
      ],
      formValue: {
        expressNum: ''
      },
      total: 0,
      pageNum: 1,
      pageSize: 15,
      loading: false,
      frameList: {
        oddNumber: '',
        weight: '',
        createTime: '',
        stockDays: '',
        note: ''
      },
      stockManage: [],
      selectList: [
        // {
        //   label: '1',
        //   value: '中国'
        // },
        // {
        //   label: '2',
        //   value: '美国'
        // }
      ]
    }
  },
  created() {
    // this.params = this.$route.params
    // console.log(this.params);
    // this._getList()
    this.getSelect()
  },
  mounted() {},
  methods: {
    _getList() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      //  oddNumber: 
        wxOddNumber:this.oddNumber
      }
      this.loading = true
      this.$reqPost(parcelInfo.list, params).then((res) => {
        this.loading = false
        if (res.data.code === 200) {
          this.shipList = res.data.data.list
        }
      })
    },
    getSelect() {
      this.$reqGet(parcelInfo.select).then((res) => {
        if (res.data.code === 200) {
          // this.selectList = res.data.data
          res.data.data.forEach(item => {
            const obj = {
              label: item.warehouseName,
              value: item.warehouseCode
            }
            this.selectList.push(obj)
          })
        }
      })
    },
    // 查询
    search() {
      this._getList()
    },
    claim() {
      this.msgTilte = '包裹认领'
      this.msgVisible = true
    },
    // 确定
    saveData() {
      const param = {
        stockManage: this.shipList.forEach(item => {
          this.id = item.id
          this.stockManage.push(this.id)
        }),
        stockManageId: this.stockManage[0],
        memberId: JSON.parse(localStorage.userInfo).id,
        memberRemark: this.frameList.note
      }
      // console.log(param);
      this.$reqPost(parcelInfo.claim, param).then((res) => {
        if (res.data.code === 200) {
          this.$toast.success('认领成功')
          this.msgVisible = false
          this._getList()
        } else {
          this.$toast.success(res.data.message)
        }
      })
    },
    // 订单详情
    claimDetails(item, index) {
      // console.log(item)
      this.msgTilte = '包裹详情'
      this.$reqGet(parcelInfo.details + '/' + item.id).then((res) => {
        if (res.data.code === 200) {
          this.frameList = res.data.data
        }
      })
      this.msgVisible = true
    },
    forceUpdate() {
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
/deep/ .title{
  background: white;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}
.title select{
  width: 20%;
  height: 40px;
  padding-left: 8px;
  margin: 10px 0;
  // background: white;
  border-right: none;
}
.title input{
  height: 40px;
  // width: 100%;
  padding: 15px;
  margin: 10px 0;
  box-sizing: border-box;
}
/deep/ .nq-button{
  // display: none;
  height: 40px;
  padding: 10px 20px;
  margin: 10px 0;
  margin-left: 15px;
  border-radius: 5px;
}
/deep/ .baoguo{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px;
  margin-top: 20px;
}
// /deep/ .flex h3{
//   padding: 5px;
//  border-bottom: 1px solid #cdcdcd;
// }
/deep/ .copyLi-button{
  padding: 5px 12px;
  // border-radius: 15px;
  background: rgb(248, 132, 0);
  margin-top: 15px;
}
/deep/ .image{
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #cdcdcd;
}
/deep/ .ware-house-item {
  margin-bottom: 8px;

  display: flex;
  span:nth-child(1) {
    width: 38%;
  }
  span:nth-child(2) {
    flex: 1;
  }
}
/deep/ .btn{
  text-align: center;
}
</style>
